<template>
  <v-stepper v-model="steps">
    <v-stepper-header>
      <v-stepper-step :complete="steps > 1" step="1">
        {{ this.$t("config_inventory") }}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="steps > 2" step="2">
        {{ this.$t("add_product_gap_reason") }}
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1" class="grey lighten-4">
        <v-card class="mb-12">
          <v-card-text>
            <v-form ref="form" v-model="is_valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    disabled
                    dense
                    hide-details="auto"
                    outlined
                    :label="$t('hub_*')"
                    v-model="inventory.hub_id"
                    :items="listHubs"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    disabled
                    dense
                    outlined
                    v-model="inventory.type"
                    :items="invTypesList"
                    item-value="id"
                    item-text="name"
                    :label="$t('inventory_type')"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    disabled
                    dense
                    outlined
                    v-model="inventory.category_parent_id"
                    :items="rootCategories"
                    item-value="id"
                    item-text="name"
                    :label="$t('category_*')"
                    :loading="isLoadingCategories"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    disabled
                    dense
                    outlined
                    v-model="inventory.category_id"
                    :items="leafCategories"
                    item-value="id"
                    item-text="name"
                    :label="$t('sub_category_*')"
                    :loading="isLoadingCategories"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    disabled
                    dense
                    outlined
                    :label="$t('tracking_number')"
                    v-model="inventory.tracking_number"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- tabs all product / in inventory -->
        <v-card class="mx-auto">
          <v-toolbar dark tabs flat color="#6100ea" class="hidden-xs-only">
            <template>
              <v-tabs v-model="tabs" align-with-title>
                <v-tab href="#all"> PRODUCT LIST </v-tab>
                <v-tab href="#inventory" @click="loadInventoryProducts()">
                  ITEMS IN INVENTORY
                  <span
                    style="
                      background: white;
                      color: black;
                      border-radius: 10px;
                      width: 50px;
                      text-align: center;
                      margin-left: 10px;
                    "
                  >
                    {{ nbr_items }}</span
                  >
                </v-tab>
                <v-tab href="#arbitre" v-if="display_arbitre">
                  ITEMS IN INVENTORY Arbitration
                  <span
                    style="
                      background: white;
                      color: black;
                      border-radius: 10px;
                      width: 50px;
                      text-align: center;
                      margin-left: 10px;
                    "
                  >
                    {{ nbr_items_arbitre }}</span
                  >
                </v-tab>
                <v-tabs-slider color="pink"></v-tabs-slider>
              </v-tabs>
            </template>

            <delete-inventory-item-dialog
              v-if="$admin.can('inventory-delete')"
            ></delete-inventory-item-dialog>
          </v-toolbar>

          <v-tabs-items v-model="tabs">
            <v-tab-item value="all">
              <v-container id="regular-tables" fluid>
                <v-data-table
                  :headers="headersAll"
                  :items="listProducts"
                  :loading="isLoadingProducts"
                  :server-items-length="productsMeta.totalItems"
                  :options.sync="all_products_options"
                  :footer-props="{ 'items-per-page-options': [5, 10, 20] }"
                  :single-expand="singleExpandAll"
                  :expanded.sync="expandedAll"
                  item-key="code"
                  show-expand
                >
                  <template v-slot:item.image="{ item }">
                    <v-img
                      v-if="item.image"
                      :src="item.image.url"
                      max-height="50"
                      max-width="50"
                    />
                    <v-img
                      v-else
                      src="@/assets/product.png"
                      max-height="50"
                      max-width="50"
                    />
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <table class="table_inventory">
                        <tr class="header">
                          <th>{{ $t("batch_number") }}</th>
                          <th>{{ $t("batch_expiration_date") }}</th>
                          <th>{{ $t("batch_quantity") }}</th>
                        </tr>
                        <tr
                          v-for="(next, i) in item.active_lots"
                          :key="i"
                          class="elements"
                        >
                          <td>
                            {{
                              next.lot_number != undefined
                                ? next.lot_number
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              next.expiration_date != undefined
                                ? next.expiration_date
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              next.qte_in_lot != undefined
                                ? next.qte_in_lot
                                : ""
                            }}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="primary"
                      v-if="$admin.can('inventory-update')"
                      small
                      class="mr-2"
                      @click="addItem(item)"
                    >
                      add
                    </v-btn>
                  </template>
                  <template v-slot:no-data>
                    {{ $t("no_data_available") }}
                  </template>
                  <template v-slot:top>
                    <v-row class="pa-4">
                      <v-text-field
                        v-model="all_products_options.search"
                        :label="$t('search')"
                        class=""
                        @keyup.enter="updateProductByEnterKey"
                      ></v-text-field>
                      <v-btn
                        disabled
                        class="ms-4"
                        color="primary"
                        :loading="is_adding_all_products"
                        @click="addAll"
                        >+ Add All</v-btn
                      >
                    </v-row>
                  </template>
                </v-data-table>
              </v-container>
            </v-tab-item>
            <v-tab-item value="inventory">
              <v-data-table
                :headers="headersInventory"
                :items="listInventoryProducts"
                :loading="isLoadingProducts"
                :server-items-length="inventoryProductsMeta.totalItems"
                :options.sync="inventory_products_options"
                :footer-props="{ 'items-per-page-options': [5, 10, 20] }"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="code"
                show-expand
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <table class="table_inventory">
                      <tr class="header">
                        <th>{{ $t("batch_number") }}</th>
                        <th>{{ $t("batch_expiration_date") }} 1</th>
                        <th>{{ $t("batch_quantity") }} 1</th>
                        <th>{{ $t("batch_quantity_real") }} 1</th>

                        <template
                          v-if="
                            item?.adjustement_lots_2 != undefined &&
                            item?.adjustement_lots_2[0]
                          "
                        >
                          <th>{{ $t("batch_expiration_date") }} 2</th>
                          <th>{{ $t("batch_quantity_real") }} 2</th>
                          <th>{{ $t("ecart_quantity") }}</th>
                        </template>
                      </tr>
                      <tr
                        v-for="(next, i) in item.adjustement_lots_1"
                        :key="i"
                        class="elements"
                      >
                        <td>
                          {{
                            next != undefined && next?.lot_number != undefined
                              ? next?.lot_number
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            next != undefined &&
                            next?.expiration_date != undefined
                              ? next?.expiration_date
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            next != undefined && next?.qte_in_lot != undefined
                              ? next?.qte_in_lot
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            next != undefined &&
                            next?.adjustment_quantity != undefined
                              ? next?.adjustment_quantity
                              : ""
                          }}
                        </td>

                        <template
                          v-if="
                            item?.adjustement_lots_2 != undefined &&
                            item?.adjustement_lots_2[i]
                          "
                        >
                          <td>
                            {{
                              item?.adjustement_lots_2 != undefined &&
                              item?.adjustement_lots_2[i] != undefined
                                ? item?.adjustement_lots_2[i]?.expiration_date
                                : $t("not_yet")
                            }}
                          </td>
                          <td>
                            {{
                              item?.adjustement_lots_2 != undefined &&
                              item?.adjustement_lots_2[i] != undefined
                                ? item?.adjustement_lots_2[i]
                                    ?.adjustment_quantity
                                : $t("not_yet")
                            }}
                          </td>
                          <td>
                            {{
                              item?.adjustement_lots_2 != undefined &&
                              item?.adjustement_lots_2[i] != undefined
                                ? item?.adjustement_lots_2[i]
                                    ?.adjustment_quantity -
                                  next?.adjustment_quantity
                                : $t("not_yet")
                            }}
                          </td>
                        </template>
                      </tr>
                    </table>
                  </td>
                </template>

                <template v-slot:item.image="{ item }">
                  <v-img
                    v-if="item.image"
                    :src="item.image.url"
                    max-height="50"
                    max-width="50"
                  />
                  <v-img
                    v-else
                    src="@/assets/product.png"
                    max-height="50"
                    max-width="50"
                  />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="removeProduct(item)">
                    mdi-delete
                  </v-icon>
                </template>

                <template v-slot:top>
                  <v-row class="pa-4">
                    <v-text-field
                      v-model="all_products_options.search"
                      :label="$t('search')"
                      class=""
                      @keyup.enter="updateProductByEnterKey"
                    ></v-text-field>
                    <v-btn
                      class="ms-4"
                      color="primary"
                      :loading="is_removing_all_products"
                      :disabled="listInventoryProducts.length === 0"
                      @click="removeAll"
                      >- Remove All</v-btn
                    >
                  </v-row>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item value="arbitre">
              <v-row style="margin: 25px; width: 100%">
                <template style="float: right">
                  <import-CSV
                    :InventoryData="inventory"
                    v-if="$admin.can('inventory-create')"
                  ></import-CSV>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn
                          v-if="$admin.can('inventory-update')"
                          small
                          class="mr-2"
                          @click="() => exportBATCHCSVArbitration()"
                          elevation="2"
                          >Download Ecart</v-btn
                        >
                      </span>
                    </template>
                    <span>{{ $t("export_inventory_batch_csv") }}</span>
                  </v-tooltip>
                </template>
              </v-row>
              <v-data-table
                :headers="headersInventory"
                :items="listProductsArbitration"
                :loading="isLoadingProducts"
                :server-items-length="productsMetaArbitration?.totalItems"
                :options.sync="inventory_products_options"
                :footer-props="{ 'items-per-page-options': [5, 10, 20] }"
                :single-expand="singleExpandArbitre"
                :expanded.sync="expandedArbitre"
                item-key="code"
                show-expand
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <table class="table_inventory">
                      <tr class="header">
                        <template
                          v-if="
                            item?.adjustement_lots_3 != undefined &&
                            item?.adjustement_lots_3[0]
                          "
                        >
                          <th>{{ $t("batch_number") }}</th>
                          <th>{{ $t("batch_expiration_date") }}</th>
                          <th>{{ $t("batch_quantity_real") }}</th>
                          <th>{{ $t("batch_quantity") }}</th>
                          <th>{{ $t("ecart_quantity") }}</th>
                        </template>
                      </tr>
                      <tr
                        v-for="(next, i) in item.adjustement_lots_3"
                        :key="i"
                        class="elements"
                      >
                        <td>
                          {{
                            next != undefined && next?.lot_number != undefined
                              ? next?.lot_number
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            next != undefined &&
                            next?.expiration_date != undefined
                              ? next?.expiration_date
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            next != undefined && next?.qte_in_lot != undefined
                              ? next?.qte_in_lot
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            next != undefined &&
                            next?.adjustment_quantity != undefined
                              ? next?.adjustment_quantity
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            next != undefined && next?.adjustment_quantity
                              ? next?.adjustment_quantity - next?.qte_in_lot
                              : $t("not_yet")
                          }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </template>
                <template v-slot:item.image="{ item }">
                  <v-img
                    v-if="item.image"
                    :src="item.image.url"
                    max-height="50"
                    max-width="50"
                  />
                  <v-img
                    v-else
                    src="@/assets/product.png"
                    max-height="50"
                    max-width="50"
                  />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="removeProduct(item)">
                    mdi-delete
                  </v-icon>
                </template>

                <template v-slot:top>
                  <v-row class="pa-4">
                    <v-text-field
                      v-model="all_products_options.search"
                      :label="$t('search')"
                      class=""
                      @keyup.enter="updateProductByEnterKey"
                    ></v-text-field>
                    <v-btn
                      class="ms-4"
                      color="primary"
                      :loading="is_removing_all_products"
                      :disabled="listInventoryProducts.length === 0"
                      @click="removeAll"
                      >- Remove All</v-btn
                    >
                  </v-row>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <!-- end products tabs -->

        <v-container id="regular-tables" fluid tag="section">
          <edit-product-quantity v-if="steps == 1"></edit-product-quantity>
        </v-container>
        <v-btn
          color="primary"
          :loading="is_adding_all_products"
          @click="updateQuantities()"
        >
          Continue
        </v-btn>

        <v-btn text @click="returnToList"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <product-gap-reason-step
          :steps="this.steps"
          :inventory_id="this.inventory.id"
        ></product-gap-reason-step>
        <v-btn
          color="primary"
          :loading="is_adding_all_products"
          @click="finish()"
        >
          Finish
        </v-btn>

        <v-btn text @click="steps = 1"> Back </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import "../../../../../src/css/inventory_create.css";
export default {
  mixins: [validationRules],

  async created() {
    await this.$store.dispatch("hubs/list");
    await this.$store.dispatch("categories/listRoots");
    await this.$store.dispatch("inventories/closeForm");
    await this.$store.dispatch("products/closeForm");
    await this.$store.dispatch("inventories/clearState");
    await this.$store.dispatch("inventories/show", {
      id: this.$route.params.id,
    });
    await this.getInventoryProducts();
    await this.getAllProducts();
  },

  components: {
    // ProductQuantitiesStep: () =>
    //   import("./components/steps/ProductQuantitiesStep.vue"),
    ProductGapReasonStep: () =>
      import("./components/steps/ProductReasonStep.vue"),
    editProductQuantity: () =>
      import("./components/dialogs/UpdateInventoryItemQuantity.vue"),
    DeleteInventoryItemDialog: () =>
      import("./components/dialogs/DeleteInventoryItemDialog.vue"),
    ImportCSV: () => import("./components/dialogs/ImportCSVBatchArbitre.vue"),
  },

  computed: {
    ...mapGetters({
      listHubs: "hubs/activeHubs",
      inventory: "inventories/getInventory",
      rootCategories: "categories/listRoots",
      leafCategories: "categories/listLeafs",
      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",

      product: "inventories/product",
      listInventoryProducts: "inventories/listProducts",
      inventoryProductsMeta: "inventories/productsMeta",
      listProductsArbitration: "inventories/listProductsArbitration",
      productsMetaArbitration: "inventories/productsMetaArbitration",
    }),

    category_parent_id() {
      return this.inventory.category_parent_id;
    },
    inventory_id() {
      return this.inventory.id;
    },
  },

  watch: {
    category_parent_id: function (id) {
      this.$store.dispatch("categories/listLeafs", { parent_id: id });
    },

    all_products_options: {
      handler: debounce(async function () {
        this.getAllProducts();
      }, 1000),
      deep: true,
    },

    inventory: {
      handler: debounce(async function () {
        this.getAllProducts();
      }, 1000),
      deep: true,
    },

    inventory_products_options: {
      async handler() {
        this.getInventoryProducts();
      },
      deep: true,
    },
  },

  methods: {
    async exportBATCHCSVArbitration() {
      var item = this.inventory;
      console.log("Export CSV ", item);
      await this.$store
        .dispatch("inventories/exportBATCHCSVArbitration", item)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "inventory_BATCH_" + item.tracking_number + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
        });
    },

    sendData(data) {
      console.log(data);
    },
    loadInventoryProducts() {
      this.getInventoryProducts();
      setTimeout(() => {
        this.nbr_items = this.inventoryProductsMeta.totalItems;
        this.nbr_items_arbitre = this.productsMetaArbitration?.totalItems;
        console.log("adding data ", this.productsMetaArbitration?.totalItems);
        if (this.nbr_items_arbitre != 0) {
          this.display_arbitre = true;
        } else {
          this.display_arbitre = false;
        }
      }, 1500);
    },
    async getAllProducts() {
      if (this.inventory.hub_id) {
        this.is_loading_product = true;
        const params = {
          ...this.all_products_options,
          with_stock: true,
          hub_id: this.inventory.hub_id,
          category_id: this.inventory.category_id,
          category_parent_id: this.inventory.category_parent_id,
        };
        await this.$store.dispatch("products/root", params).then(() => {
          this.is_loading_product = false;
          if (
            this.all_products_options.page > (this.productsMeta.lastPage || 1)
          ) {
            this.all_products_options.page = 1;
          }
        });
      }
    },

    async getInventoryProducts() {
      if (this.inventory_id && this.steps == 1) {
        this.is_loading_product = true;

        await this.$store
          .dispatch("inventories/listProducts", {
            inventory_id: this.inventory_id,
            ...this.inventory_products_options,
          })
          .then(async () => {
            await this.$store
              .dispatch("inventories/listProductsArbitration", {
                inventory_id: this.inventory_id,
                ...this.inventory_products_options,
              })
              .then(() => {
                this.is_loading_product = false;
              });

            //this.is_loading_product = false;
          });
      }
    },

    async saveInventory() {
      if (this.$refs.form.validate()) {
        try {
          this.is_saving_inventory = true;
          if (this.inventory.id) {
            await this.$store.dispatch("inventories/update", this.inventory);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store.dispatch("inventories/add", this.inventory);
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }
          // this.steps = 2;
          this.is_saving_inventory = false;
        } catch (error) {
          this.is_saving_inventory = false;
          console.log(error);
          this.$store.dispatch("alerts/error", error.response.data.message);
          this.formErrors = error.response.data.errors;
        }
      }
    },

    async addItem(item) {
      if (!this.inventory_id) {
        await this.saveInventory();
      }
      item.inventory_id = this.inventory_id;
      await this.$store.dispatch("inventories/addProduct", item).then(() => {
        this.$store.dispatch("alerts/success", this.$t("updated_successfully"));
      });
    },

    async editItem(item) {
      if (!this.inventory_id) {
        await this.saveInventory();
      }
      item.inventory_id = this.inventory_id;
      if (item.inventory_id)
        await this.$store
          .dispatch("inventories/openEditProduct", item)
          .then(() => this.getInventoryProducts());
    },

    removeProduct(item) {
      this.$store.dispatch("inventories/openDeleteInventoryItemDialog", item);
    },

    async addAll() {
      this.is_adding_all_products = true;
      if (!this.inventory_id) {
        await this.saveInventory();
      }
      await this.$store
        .dispatch("inventories/addAllProducts", {
          with_stock: true,
          hub_id: this.inventory.hub_id,
          category_id: this.inventory.category_id,
          category_parent_id: this.inventory.category_parent_id,
        })
        .then(() => this.getInventoryProducts());

      this.is_adding_all_products = false;

      this.$store.dispatch("alerts/success", this.$t("updated_successfully"));
    },

    async removeAll() {
      this.is_removing_all_products = true;

      await this.$store
        .dispatch("inventories/removeAllProducts", {
          hub_id: this.inventory.hub_id,
        })
        .then(() => this.getInventoryProducts());

      this.is_removing_all_products = false;
      this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
    },

    async updateQuantities() {
      try {
        this.is_adding_all_products = true;
        await this.getInventoryProducts();
        await this.$store
          .dispatch("inventories/checkPhysicalQte", this.inventory.id)
          .then(() => {
            this.is_adding_all_products = false;
            this.steps = 2;
          });
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response.data.message);
      }
    },

    async finish() {
      try {
        this.is_loading_product = true;
        await this.$store
          .dispatch("inventories/checkGapReasons", this.inventory_id)
          .then(async () => {
            await this.$store.dispatch(
              "inventories/lastStep",
              this.inventory_id
            );
          })
          .then(async () => {
            this.is_loading_product = false;
            await this.$store.dispatch("inventories/closeForm");
            this.$router.push({ name: "inventories.index" });
          });
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response.data.message);
      }
    },

    returnToList() {
      this.$router.push({ name: "inventories.index" });
    },
  },

  data() {
    return {
      singleExpandArbitre: false,
      expandedArbitre: [],
      nbr_items_arbitre: 0,
      display_arbitre: false,
      nbr_items: 0,
      singleExpand: false,
      expanded: [],
      singleExpandAll: false,
      expandedAll: [],
      steps: 1,
      is_loading_product: false,
      isLoadingCategories: false,
      is_valid: false,
      inventory_products_options: { itemsPerPage: 10 },
      all_products_options: { itemsPerPage: 10 },
      tabs: true,
      is_saving_inventory: false,
      is_adding_all_products: false,
      is_removing_all_products: false,
      invTypesList: [
        { id: "global", name: "Inventaire Globale" },
        { id: "periodic", name: "Inventaire Tournant" },
      ],
      headersAll: [
        {
          text: this.$t("batch_number_dlc_quantity"),
          value: "data-table-expand",
        },
        {
          text: this.$t("sku"),
          align: "start",
          sortable: false,
          value: "sku",
        },
        {
          text: this.$t("code"),
          align: "start",
          value: "code",
          sortable: true,
        },
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },

        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },

        {
          text: this.$t("theoretical_qte"),
          value: "qte_in_hub",
        },

        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
      headersInventory: [
        {
          text: this.$t("batch_number_dlc_quantity"),
          value: "data-table-expand",
        },
        {
          text: this.$t("sku"),
          align: "start",
          sortable: false,
          value: "sku",
        },
        {
          text: this.$t("code"),
          align: "start",
          value: "code",
          sortable: true,
        },
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },

        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("theoretical_qte"),
          value: "qte",
        },
        {
          text: this.$t("added_by_1"),
          value: "added_by_1",
        },
        {
          text: this.$t("added_by_2"),
          value: "added_by_2",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
};
</script>